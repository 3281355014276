import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { ContentActions } from '../state/ContentState'
import Article from '../components/Article'

function renderArticleView(article) {
  return (
    <section className="article-page">
      <section>
        <div className="columns is-centered pageHeader">
          <div className="column is-half-desktop">
            <h1>{article.title}</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="columns is-centered pageHeader">
          <div className="column is-half-desktop">
            <Article article={article} />
          </div>
        </div>
      </section>
    </section>
  )
}

function renderRedirect() {
  return(
    <Redirect to={'/404'} />
  )
}

function ArticlePage({ match }) {
  const articles = useSelector(state => state.content.articles )
  const articleId = parseInt(match.params.articleID)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!articles) {
      dispatch(ContentActions.getContent())
    }
  })

  if (!articleId) {
    console.log('could not find necessary url param article id, going to 404');
    return renderRedirect()
  }

  if (!articles) {
    return (
      <div><h1>Loading</h1></div>
    )
  }

  if (articles) {
    const article = articles[parseInt(articleId)]

    if (!article) {
      return renderRedirect()
    }

    return renderArticleView(article)
  }

}

export default ArticlePage
