import React from 'react'
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Provider } from 'react-redux'

import './App.sass'
import SeriesPage from './containers/SeriesPage'
import ArticlePage from './containers/ArticlePage'
import SeriesArticlesPage from './containers/SeriesArticlesPage'

import { store } from './state/store'
import ScrollToTop from './components/ScrollToTop'

function NotFoundPage() {
  return (
    <h1>We could not find what you were looking for</h1>
  )
}

function App() {
  const renderRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={SeriesPage} />
        <Route exact path="/series/:seriesID" component={SeriesArticlesPage} />
        <Route exact path="/articles/:articleID" component={ArticlePage} />
        <Route exact path="/404" component={NotFoundPage} />
      </Switch>
    )
  }

  const supportsHistory = 'pushState' in window.history

  return (
    <Provider store={store}>
      <Router forceRefresh={!supportsHistory}>
        <ScrollToTop>
          <div>
            <nav className="navbar has-shadow">
              <div className="navbar-brand">
                <Link className="navbar-item logo-copy" to="/">
                  All Mental Health
                </Link>
              </div>
            </nav>

            <section className="app-body is-large">
              <div className="container">
                {renderRoutes()}
              </div>
            </section>
          </div>
        </ScrollToTop>
      </Router>
    </Provider>
  )
}

export default App;
