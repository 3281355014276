import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ContentActions } from '../state/ContentState'
import ItemList from '../components/ItemList'

function renderSeriesList(series) {
  const seriesForList = Object.entries(series).map(([_, s]) => {
    return {
      id: s.id,
      title: s.display_title,
      summary: s.description,
      href: '/series/' + s.id.toString(),
    }
  })

  return (
    <section className="series-page">
      <div className="columns is-centered pageHeader">
        <div className="column has-text-centered">
          <h1>What would you like to focus on?</h1>
        </div>
      </div>

      <ItemList items={seriesForList} />
    </section>
  )
}

function SeriesPage() {
  const dispatch = useDispatch()
  const series = useSelector(state => state.content.series)

  useEffect(() => {
    if (!series) {
      dispatch(ContentActions.getContent())
    }
  })

  if (!series) {
    return (
      <div><h1>loading</h1></div>
    )
  }

  return renderSeriesList(series)
}

export default SeriesPage
